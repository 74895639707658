<template>
    <v-card rounded="lg" class="my-4" elevasion="4">
        <v-skeleton-loader
            class="mx-auto"
            type="card"
            v-if="loading"
            />
        <template v-else>
            <v-card-title> Game Status </v-card-title>
            <v-card-text>
                <v-divider class="mb-2"/>
                <v-chip label class="ma-1" color="success"> {{ `${mssp.PLAYERS} Player${mssp.PLAYERS == 1 ? '' : 's'} Online` }}</v-chip>
                <v-chip label class="ma-1"> {{ mssp.STATUS }}</v-chip>
                <v-chip label class="ma-1"> {{ mssp.GENRE }}</v-chip>
                <v-chip label class="ma-1"> {{ mssp.SUBGENRE }}</v-chip>
                <v-chip label class="ma-1"> {{ mssp.LANGUAGE }}</v-chip>
                <v-chip label class="ma-1" color="purple"> {{ mssp["MINIMUM AGE"] }}</v-chip>
                <v-chip label class="ma-1"> {{ `Up Since ${new Date(mssp.UPTIME * 1000).friendlyString()}`}} </v-chip>
            </v-card-text>
        </template>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import {
  FETCH_MSSP
} from "@/store/actions.type";

export default {
  name: "BoardThread",
  computed: {
    ...mapGetters([ 'mssp' ]),
  },
  created() {
    this.loading = true;
    Promise.all([
      store.dispatch(FETCH_MSSP),
    ]).then(() => {
        this.loading = false;
    });
  },
  data() {
    return {
        loading: false,
    }
  }
}
</script>