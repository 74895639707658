<template>
  <div class="home">
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12">
            <h1 class="display-2 text-center font-weight-bold">
              Welcome to Torchship!
            </h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2 class="headline text-center my-5">What is a MUD?</h2>
            <p class="text-center">
              MUD (Multi-User Dungeon) is a genre of online multiplayer game
              where players use text-based commands to interact with a virtual
              world. Torchship is an
              <span class="font-weight-bold">RPI (roleplay-intensive)</span> MUD
              where players are
              <span class="font-weight-bold">required to roleplay</span> in
              order to immerse themselves in the game's post-apocalyptic
              cyberpunk setting.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-center"
                >Join the Adventure!</v-card-title
              >
              <v-card-text>
                <p>
                  In Torchship, you'll be transported to a future where an
                  incomplete seedship orbits outside of the massive debris
                  fields shielding a depleted Earth. The seedship houses 1.8
                  million citizens aboard a 13 kilometer rotating ring, and
                  serves as a trade hub for the stations, belters, and captains
                  that call space their home.
                </p>
                <p>
                  In the dwindling light of humanity's last bastion, you'll have
                  the opportunity to carve out a path and make a name for
                  yourself through a dynamic reputation system. Forge your own
                  destiny and leave a lasting mark on the last flickers of
                  civilization in the universe.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-center"
                >Inspired by Popular Sci-Fi Series</v-card-title
              >
              <v-card-text>
                <p>
                  Torchship draws inspiration from popular sci-fi series such as
                  <span class="font-weight-bold">The Expanse</span>,
                  <span class="font-weight-bold">Eclipse Phase</span>,
                  <span class="font-weight-bold">Altered Carbon</span>, and
                  <span class="font-weight-bold">Citizen Sleeper</span>.
                </p>
                <p>
                  With a rich and immersive storyline, Torchship offers endless
                  hours of adventure and exploration in a world where an
                  <span class="font-weight-bold"
                    >individual's reputation is everything</span
                  >. Will you rise to power and become a leader among the
                  seedship's inhabitants, or will you fall to the dangers that
                  lurk around every corner?
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="start" justify="center">
          <v-col cols="12" md="4">
            <v-card class="mx-auto" max-width="400">
              <v-card-title class="text-center"
                >A Rich Out of Game Community</v-card-title
              >
              <v-card-text>
                <p>
                  Join our forums and Discord server to connect with other
                  players and build friendships that extend beyond the game.
                  Share tips, stories, and make memories with like-minded
                  people.
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="mx-auto" max-width="400">
              <v-card-title class="text-center"
                >Continuously Developing World</v-card-title
              >
              <v-card-text>
                <p>
                  Our world is constantly evolving and expanding, with new
                  content and coded systems added regularly. Explore new areas,
                  interact with new NPCs, and discover new storylines as we
                  continue to build and improve the game.
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="mx-auto" max-width="400">
              <v-card-title class="text-center"
                >Extreme Character Customization</v-card-title
              >
              <v-card-text>
                <p>
                  Express yourself fully with our deep character customization
                  and tailoring systems. From chargen to outfitting, your
                  character will truly feel like your own unique creation, with
                  endless possibilities for customization and personalization.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-card rounded="lg" align="center" justify="space-around">
          <v-card-text>
            <a href="http://play.torchship.org/player-client/">
              <v-btn color="primary" large width="80%"> Play Now </v-btn>
            </a>
          </v-card-text>
        </v-card>
        <v-card rounded="lg" class="my-4" elevasion="4">
          <v-card-title> Connection Information </v-card-title>
          <v-card-text>
            <v-divider />
            <p>Host: moo.torchship.org</p>
            <p>Port: 7777</p>
          </v-card-text>
        </v-card>
        <v-card rounded="lg" class="my-4" elevasion="4" v-if="is_logged_in">
          <v-card-title> Join Us On Discord! </v-card-title>
          <v-card-text>
            <v-divider />
            <v-btn
              color="success"
              href="https://discord.com/api/oauth2/authorize?client_id=1070183038971019294&redirect_uri=https%3A%2F%2Ftorchship.org%2Fdiscord&response_type=code&scope=identify%20email%20guilds.join"
            >
              Link Discord Account
            </v-btn>
          </v-card-text>
        </v-card>
        <MSSPCard />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import MSSPCard from "@/components/MSSPCard.vue";

export default Vue.extend({
  name: "Home",
  components: { MSSPCard },
  computed: {
    ...mapGetters(["is_logged_in", "user_profile"]),
  },
});
</script>
